.parent {
    border: 1px solid black;
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
  }
  .child {
    display: inline-block;
    border: 1px solid red;
    padding: 1rem 1rem;
    vertical-align: middle;
  }

  .float-parent-element { 
    width: 50%; 
} 
.float-child-element { 
    float: left; 
    width: 50%; 
} 
.red {
    margin-left: 0%; 
} 
.yellow { 
    margin-left: 100%; 
}

.box {
    text-align: center;
    position: relative;
    line-height: 60px;
    background: #fff;
    height: 10px;
    width: "100%";
  }
  
  .box:after {
    background: linear-gradient(to right, #bcbcbc 25%,#ffcd02 25%, #ffcd02 50%, #e84f47 50%, #e84f47 75%, #65c1ac 75%);
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    left: 0;
    top: 0;
  }


  /* Adjust the styles based on your needs */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.field {
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
   
}

.label {
  font-weight: bold;
  margin-right: 10px;
  min-width: 150px; /* Set a minimum width for labels */
}


.label1 {
  /* font-weight: bold; */
  margin-right: 10px;
  min-width: 150px; /* Set a minimum width for labels */
}
