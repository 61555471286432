.parent {
    border: 1px solid black;
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
  }
  .child {
    display: inline-block;
    border: 1px solid red;
    padding: 1rem 1rem;
    vertical-align: middle;
  }

  .float-parent-element { 
    width: 50%; 
} 
.float-child-element { 
    float: left; 
    width: 50%; 
} 
.red {
    margin-left: 0%; 
} 
.yellow { 
    margin-left: 100%; 
}

.box {
    text-align: center;
    position: relative;
    line-height: 60px;
    background: #fff;
    height: 10px;
    width: "100%";
  }
  
  .box:after {
    background: linear-gradient(to right, #bcbcbc 25%,#ffcd02 25%, #ffcd02 50%, #e84f47 50%, #e84f47 75%, #65c1ac 75%);
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    left: 0;
    top: 0;
  }
  
.print-preview-button {
  @media print {
    display: none;
  }
}
  
.print-components {
  display: table;
  width: 100%;
}

.print-components th,
.print-components td {
  text-align: left;
  width:17%;
}
.bold-result {
  font-weight: bold;
}

@media print {
  .print-preview-button {
    display: none;
  }
  .bold-result {
    font-weight: bold;
}

  .print-component {
    display: table;
    width: 100%;
  }
    .print-component .table-footer > tr > td > th {
      text-align:left;
      color: white;
      width:17%;
      padding: 4px;
    }
}
